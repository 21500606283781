import styled from "styled-components";
import { ScaleUpCenter, focusIn } from "./Keyframes";



export const HeaderB = styled.h1`
  font-family: 'Poiret One', cursive;
  font-size: 4.5em;
  font-weight: bold;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
  // animation: ${focusIn} 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  `;

export const Header2 = styled.h2`
  padding-top: 50px;
  font-family: "Poiret One", cursive;
  font-size: 2.5em;
  text-align: center;
  animation: ${focusIn} 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
`;

export const Header3 = styled.h2`
  font-family: "Raleway", sans-serif;
  font-size: 2em;
  text-align: center;
`;

export const Button = styled.div`
  font-family: "Raleway", sans-serif;
  padding: 15px 32px;
  margin-top: 25px;
  text-align: center;
  font-size: 20px;
  width: 200px;
  color: black;
  border: 2px solid black;
  border-radius: 4px;
  background-color: rgb(86, 112, 109, 1);
  opacity: 0.5;
  animation: ${focusIn} 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
`;

export const Content = styled.p`
  fontfamily: "Raleway", sans-serif;
  padding-top: 10px;
  text-align: center;
  font-size: 20px;
  animation: ${focusIn} 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
`;

export const FadeDiv = styled.div`
  animation: ${focusIn} 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
`;

export const ExpandDiv = styled.div`
  animation: ${ScaleUpCenter} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const Cacti = styled.image`
    background-repeat: no-repeat;
  }`;

export const About = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

export const Photo = styled.image`
  maxHeight: 100%;
  maxWidth: 100%;
  animation: ${focusIn} 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}`;
