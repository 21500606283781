import React from "react";
import { Header2, Button, } from '../Styles/AppStyles';
import Cactif from '../Assets/Images/cactif.jpg';



const Contact = () => (
  <div>
    <img src={Cactif} style={styles.background} />
    <div style={styles.header}>
    <Header2>Let's Work Together!</Header2>
    <div style={styles.contact}>
    <a href="mailto:jessie.leigh30@gmail.com" target="_blank" rel="noopener noreferrer">
      <Button>Email Me</Button></a>
      </div>
      </div>
  </div>



)

export default Contact;

const styles = {
  flexbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'flex-start',
  },

  background: {
    // backgroundColor:"#F2F3F5",
    // background:"url('./src/Assets/Images/cacti.jpg')",
    height: "100vh",
    width: "100%",
    display: "flex",
    objectFit: "cover"
  },
  
  header: {
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  contact: {
    display: "flex",
    justifyContent: "center",

  },
 

}