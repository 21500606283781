import React from 'react';
import { Menu, } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const Navbar = () => (
  <Menu style ={styles.nav}>
  <div class= 'nav'>
    <Link to="/">
      <Menu.Item>
        Home
      </Menu.Item>
    </Link>
    </div>
    {/* <div class= 'nav'>
    <Link to="/projects">
      <Menu.Item>
        My Work
      </Menu.Item>
    </Link>
    </div> */}
    <div class= 'nav'>
    <Link to="/contact">
      <Menu.Item>
        Contact
      </Menu.Item>
    </Link>
    </div>
  </Menu>
)

export default Navbar;

const styles = {
  nav: {
    fontFamily: "'Raleway', sans-serif,",
    // backgroundColor: '#F2F3F5',
    fontSize: '20px',
    // paddingTop: '10px',
    // marginLeft: '40px',
    fontWeight: 'bold',
    marginBottom: '0',
    marginTop: '0',
    position: 'sticky',
  }
}