import React from "react";
import { Header2, Content, HeaderB, FadeDiv } from "../Styles/AppStyles";
import { Image, Grid, Container } from "semantic-ui-react";
import Terra from "../Assets/Images/terra.png";
import Gallery from "../Assets/Images/gallery.png";
import Rooms from "../Assets/Images/rooms.png";
import Rps1 from "../Assets/Images/rps1.png";
import Rps2 from "../Assets/Images/rps2.png";

const Projects = () => (
  <div style={styles.background}>
    <HeaderB> Recent Projects </HeaderB>
    <FadeDiv>
      <Container>
        <Grid stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header2>Terra Nova Cabins</Header2>
              <Content>
                This is a hotel reservation website, built using Ruby on Rails
                for the back-end and React for the front-end. I worked on the
                both the front-end and back-end, but my main focus on this
                project was the UI.
              </Content>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={styles.card}>
                <a
                  href="https://terranovacabins.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image src={Terra} style={styles.image} />
                </a>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column width={8}>
            <div style={styles.card}>
              <a
                href="https://terranovacabins.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={Gallery} style={styles.image} />
              </a>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={styles.card}>
              <a
                href="https://terranovacabins.herokuapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={Rooms} style={styles.image} />
              </a>
            </div>
          </Grid.Column>
          <Grid.Column width={16}>
            <Header2> Rock Paper Scissors</Header2>
            <Content>
              A basic rock, paper scissors game built using HTML, CSS and
              Javascript.
            </Content>
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={styles.card}>
              <Image src={Rps1} style={styles.image} />
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={styles.card}>
              <Image src={Rps2} style={styles.image} />
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </FadeDiv>
  </div>
);

export default Projects;

const styles = {
  background: {
    backgroundColor: "#F2F3F5",
    height: "200vh",
  },
  flexbox: {
    display: "flex",
    flexDirection: "row",
  },

  // image: {
  //   maxHeight: "100%",
  //   maxWidth: "100%",
  // },
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: "20px",
  },
  card: {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    padding: "20px 20px 20px 20px",
    height: "300px",
    width: "465px",
    textAlign: "center",
    margin: "10px, 10px, 10px, 10px",
    backgroundColor: "white",
  },
};
