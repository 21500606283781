import React from 'react';
import Aboutme from './AboutMe/Aboutme';



const Home = () => (
  <div>
    <Aboutme />
  </div>
)


export default Home;
