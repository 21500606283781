import React from "react";
import { Icon, Container } from "semantic-ui-react";
import { Wrapper, Text, TextWrapper, ExpandDiv, Header } from "./styles";

const Aboutme = () => (
  <Wrapper>
    <Container>
      <Header>
        <h1>Jessica Leigh Anderson</h1>
      </Header>
      <ExpandDiv>
        <TextWrapper>
          <Text>
            Hello! I am a writer and full-stack web developer based in Salt Lake
            City, Utah. I have a wide range of skills but my passion is design
            and I love working in JavaScript, and React. When I am not coding, I
            enjoy traveling, writing and curling up with a good book.
          </Text>
        </TextWrapper>
        <div>
          {/* <Header2>Follow Me</Header2> */}
          <a
            href="https://github.com/jessieleigh30/"
            target="blank"
            rel="noopener noreferrer"
          >
            <Icon name="github" size="large" />
          </a>
          <a
            href="https://www.instagram.com/jessieleigh30"
            target="blank"
            rel="noopener noreferrer"
          >
            <Icon name="instagram" size="large" />
          </a>
          <a
            href="https://twitter.com/jessie_leigh30"
            target="blank"
            rel="noopener noreferrer"
          >
            <Icon name="twitter" size="large" />
          </a>
          <a
            href="https://www.linkedin.com/in/jessica-leigh-anderson/"
            target="blank"
            rel="noopener noreferrer"
          >
            <Icon name="linkedin" size="large" />
          </a>
        </div>
      </ExpandDiv>
    </Container>
  </Wrapper>
);

export default Aboutme;
