import React, { Fragment, } from 'react';
import { Route, Switch, } from 'react-router-dom';
import Home from './Components/Home';
import Projects from './Components/Projects';
import NoMatch from './Components/NoMatch';
import Navbar from './Components/Navbar';
import Contact from './Components/Contact';
import Aboutme from './Components/AboutMe/Aboutme';
import './App.css';

const App = () => (
  <Fragment>
    <Navbar />
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/aboutme' component={Aboutme} />
      <Route exact path='/projects' component={Projects} />
      <Route exact path='/contact' component={Contact} />
      <Route component={NoMatch} />
    </Switch>
  </Fragment>
);



export default App;
