import styled from "styled-components";
import Desktopf from "../../Assets/Images/desktopf.jpg";
import { ScaleUpCenter } from "../../Styles/Keyframes";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${Desktopf});
  background-position: center;
  background-repeat: no-repeat, repeat;
  background-size: cover
  height: 100vh;
  & social{
    height: 200px;
         }
       `;

export const Header = styled.div`
  & h1 {
    font-size: 35px;
    font-weight: bold;
    font-family: "Poiret One", cursive;
  }
  margin-top: 25px;
  padding-top: 10px;
  text-align: center;
  padding-bottom: 10px;
  animation: ${ScaleUpCenter} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  background: white;
  @media (min-width: 768px) {
    & h1 {
      font-size: 4.5em;
    }
    padding-bottom: 30px;
    margin-top: 20px;
  }
  @media (min-width: 992px) {
    padding-top: 30px;
  }
`;

export const Text = styled.p`
  font-family: "Raleway", sans-serif;
  font-size: 25px;
  color: white;
`;

export const TextWrapper = styled.div``;

export const ExpandDiv = styled.div`
  /* animation: ${ScaleUpCenter} 1s cubic-bezier(0.39, 0.575, 0.565, 1) both; */
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  & a {
    color: white;
    height: 200px;
  }
  padding-top: 100px;
@media (min-width: 768px) {
  margin-left: 200px;
  margin-right: 200px;
  padding-top: 100px;

`;
